<template>
  <div>
    <b-form>
      <div class="grid grid-rows-2 pt-2 gap-4 w-full md:w-1/2">
        <input-required v-model="Codigo" label="Codigo" :rules="{ required:true}" max="50" />
        <div class="md:col-span-4">
          <input-required v-model="Desc"  :label="`Descrição`" :rules="{ required:true}" max="500"/>
        </div>
      </div>
    </b-form>
    <!-- Buttons -->
    <b-row class="py-4 position-btn">
      <b-col class="text-left">
        <b-button id="show-btn" @click="$bvModal.show('modal-dcb-success')" class="btnConfirm" variant="success">
          Confirmar
        </b-button>
        <b-button @click="cancelF()" class="btnCancel" variant="danger">
          Cancelar
        </b-button>
      </b-col>
    </b-row>

    <b-modal id="modal-dcb-success" hide-footer>
      <div class="d-block text-center content-modelGeneral">
        <h3 class="model-content1">
          Cadastro realizado com <br>
          <span class="success-alert"> Sucesso!</span>
        </h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputRequired from '@/@core/components/commons/inputs/InputRequired';
export default {
  name: "formAddGeral",
  components: {
    InputRequired
  },
  data() {
    return {
      Codigo: null,
      Desc: null
    }
  },
  methods: {
    cancelF() {
      this.$router.push("/dcb");
    },
  },
};
</script>

<style>
/*   Buttons   */

.btnCancel {
  background: #cf0209 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  width: 180px;
  height: 40px;
  font-weight: bold;
  border-radius: 5px;
  border-color: #cf0209;
  margin-left: 5px;
  box-shadow: 6px 6px 8px #53535359;
  font-family: "Segoe_UI_Black_2";
}

.btnConfirm {
  background: #27a844 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  width: 180px;
  height: 40px;
  font-weight: bold;
  border-radius: 5px;
  border-color: #27a844;
  box-shadow: 6px 6px 8px #53535359;
  font-family: "Segoe_UI_Black_2";
}

.btnConfirm:hover {
  background: #27a844 0% 0% no-repeat padding-box;
  color: #fff;
}

.mini-btnIncluir {
  background: #cf0209 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  width: 64px;
  height: 32px;
  font-size: 1em;
  border-radius: 5px;
  border-color: #cf0209;
  font-family: "Segoe_UI_Bold";
}

.colorRed {
  color: #cf0209;
  padding-left: 2px;
}

.position-btn {
  position: relative;
  top: 25vh;
}

.hr-position {
  width: 70% !important;
}

.success-alert {
  color: #cf0209;
  font-size: 1.2em;
  font-family: "Segoe_UI_Black_2";
}

@font-face {
  font-family: "Segoe_UI_Bold";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Segoe_UI_Black_2";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Black_2.ttf) format("truetype");
}

/*   Fields */
.fieldContent {
  margin: 0px !important;
  padding: 0px !important;
  background-color: #e6e6e6;
  text-align: left;
  border-radius: 5px 5px 0px 0px !important;
}

.field-title {
  margin: 0px 0px 0px 6px !important;
  background-color: #e6e6e6;
  color: #272727;

  font-size: 11 px;
  font-weight: bold;
  color: #272727;
}

.field-input {
  margin: 0px !important;
  padding: 0px 0px 0px 6px !important;
  height: 46px;
  font-size: 14px;
  font-weight: bold;
  color: #272727;
  text-align: left !important;
  outline: none !important;
  border-width: 0 0 3px !important;
  border-bottom: 3px solid #272727 !important;
  background-color: #e6e6e6;
}

.form-control:focus {
  color: #495057;
  background-color: #e6e6e6;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

/*   TIPO CR       */
.checkCR {
  text-align: center;
  display: flex;
  justify-content: start;
}

.title-form {
  padding-right: 40px;
  color: #cf0209;
  position: relative;
  top: 10px;
}

.title-h3 {
  color: #cf0209;
  font-size: 1.6em;
}

/*  Mobile  */

@media (max-width: 992px) {
  .title-form {
    padding-right: 40px;
    color: #cf0209;
    position: relative;
    top: 15px;
  }

  .fieldMpdd {
    padding-top: 10px;
  }

  .hr-position {
    width: 100% !important;
    position: initial;
  }

  .hr-M {
    width: 100% !important;
    position: initial;
  }

  .position-btn {
    top: 20vh;
  }

  .btnCancel {
    width: 120px;
    height: 35px;
    font-size: 0.9em;
  }

  .btnConfirm {
    width: 120px;
    height: 35px;
    font-size: 0.9em;
  }

  .model-content1 {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 25px;
    font-size: 1.2em;
  }

  .modal.show .modal-dialog {
    position: relative;
    top: 20%;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 130px !important;
  }


}
</style>