<template>
  <div>
    <header-register :showButtonView=false>DCB
    </header-register>
    <formAddGeral />
  </div>
</template>

<script>
import formAddGeral from "@/components/dcb/formAdd/formAddGeral.vue";
import HeaderRegister from "@/@core/components/register/HeaderRegister.vue";
export default {
  name: "addDcb",
  components: {
    formAddGeral,
    HeaderRegister
  },
  methods: {
    cancelF() {
      this.$router.push("/dcb");
    },
  },
};
</script>


<style>
.title-dcb {
  font-weight: 900;
  font-size: 2.5em;
  color: #cf0209;
  font-family: "Segoe_UI_Black_2";
  letter-spacing: 1px;
}

/*  Mobile  */

@media (max-width: 992px) {
  .title-dcb {
    font-size: 1.7em;
  }

}
</style>